
export default {
  cities: [
    "Abia State - Aba North",
    "Abia State - Aba South",
    "Abia State - Arochukwu",
    "Abia State - Bende",
    "Abia State - Ikwuano",
    "Abia State - Isiala Ngwa",
    "Abia State - Isuikwuato",
    "Abia State - Obi Ngwa",
    "Abia State - Ohafia",
    "Abia State - Osisioma Ngwa",
    "Abia State - Ugwunagbo",
    "Abia State - Ukwa",
    "Abia State - Umu Nneochi",
    "Abia State - Umuahia",

    "Adamawa State - Demsa",
    "Adamawa State - Fufore",
    "Adamawa State - Ganye",
    "Adamawa State - Girei",
    "Adamawa State - Gombi",
    "Adamawa State - Guyuk",
    "Adamawa State - Hong",
    "Adamawa State - Jada",
    "Adamawa State - Lamurde",
    "Adamawa State - Madagali",
    "Adamawa State - Maiha",
    "Adamawa State - Mayo-Belwa",
    "Adamawa State - Michika",
    "Adamawa State - Mubi North",
    "Adamawa State - Mubi South",
    "Adamawa State - Numan",
    "Adamawa State - Shelleng",
    "Adamawa State - Song",
    "Adamawa State - Toungo",
    "Adamawa State - Yola North",
    "Adamawa State - Yola South",

    "Akwa Ibom State - Abak",
    "Akwa Ibom State - Eastern Obolo",
    "Akwa Ibom State - Esit-Eket",
    "Akwa Ibom State - Essien Udim",
    "Akwa Ibom State - Etim-Ekpo",
    "Akwa Ibom State - Etinan",
    "Akwa Ibom State - Ibeno",
    "Akwa Ibom State - Ibesikpo Asutan",
    "Akwa Ibom State - Ibiono Ibom",
    "Akwa Ibom State - Ika",
    "Akwa Ibom State - Ikono",
    "Akwa Ibom State - Ikot Abasi",
    "Akwa Ibom State - Ikot Ekpene",
    "Akwa Ibom State - Ini",
    "Akwa Ibom State - Itu",
    "Akwa Ibom State - Mbo",
    "Akwa Ibom State - Mkpat Enin",
    "Akwa Ibom State - Nsit Atai",
    "Akwa Ibom State - Nsit Ibom",
    "Akwa Ibom State - Nsit Ubium",
    "Akwa Ibom State - Obot Akara",
    "Akwa Ibom State - Okobo",
    "Akwa Ibom State - Onna",
    "Akwa Ibom State - Oron",
    "Akwa Ibom State - Oruk Anam",
    "Akwa Ibom State - Udung Uko",
    "Akwa Ibom State - Ukanafun",
    "Akwa Ibom State - Uquo-Ibeno",
    "Akwa Ibom State - Uruan",
    "Akwa Ibom State - Urue-Offong/Oruko",
    "Akwa Ibom State - Uyo",

    "Anambra State - Aghamelu",
    "Anambra State - Aguata",
    "Anambra State - Anambra East",
    "Anambra State - Anambra West",
    "Anambra State - Anaocha",
    "Anambra State - Awka",
    "Anambra State - Ayamelum",
    "Anambra State - Dunukofia",
    "Anambra State - Ekwusigo",
    "Anambra State - Idemili",
    "Anambra State - Ihiala",
    "Anambra State - Njikoka",
    "Anambra State - Nnewi",
    "Anambra State - Ogbaru",
    "Anambra State - Onitsha",
    "Anambra State - Orumba",

    "Bauchi State - Alkaleri",
    "Bauchi State - Bauchi",
    "Bauchi State - Bogoro",
    "Bauchi State - Damban",
    "Bauchi State - Darazo",
    "Bauchi State - Dass",
    "Bauchi State - Gamawa",
    "Bauchi State - Ganjuwa",
    "Bauchi State - Giade",
    "Bauchi State - Itas/Gadau",
    "Bauchi State - Jama are",
    "Bauchi State - Katagum",
    "Bauchi State - Kirfi",
    "Bauchi State - Misau",
    "Bauchi State - Ningi",
    "Bauchi State - Shira",
    "Bauchi State - Toro",
    "Bauchi State - Warji",
    "Bauchi State - Zaki",

    "Bayelsa State - Brass",
    "Bayelsa State - Ekeremor",
    "Bayelsa State - Kolokuma Opokuma",
    "Bayelsa State - Nembe",
    "Bayelsa State - Ogbia",
    "Bayelsa State - Sagbama",
    "Bayelsa State - Southern Ijaw",
    "Bayelsa State - Yenagoa",

    "Benue State - Ado",
    "Benue State - Agatu",
    "Benue State - Apa",
    "Benue State - Buruku",
    "Benue State - Gboko",
    "Benue State - Guma",
    "Benue State - Gwer",
    "Benue State - Katsina-Ala",
    "Benue State - Konshisha",
    "Benue State - Kwande",
    "Benue State - Logo",
    "Benue State - Makurdi",
    "Benue State - Obi",
    "Benue State - Ogbadibo",
    "Benue State - Ohimini",
    "Benue State - Oju",
    "Benue State - Okpokwu",
    "Benue State - Otukpo",
    "Benue State - Tarka",
    "Benue State - Ukum",
    "Benue State - Ushongo",
    "Benue State - Vandeikya",

    "Borno State - Abadam",
    "Borno State - Askira/Uba",
    "Borno State - Bama",
    "Borno State - Bayo",
    "Borno State - Biu",
    "Borno State - Chibok",
    "Borno State - Damboa",
    "Borno State - Dikwa",
    "Borno State - Gubio",
    "Borno State - Guzamala",
    "Borno State - Gwoza",
    "Borno State - Hawul",
    "Borno State - Jere",
    "Borno State - Kaga",
    "Borno State - Kala/Balge",
    "Borno State - Konduga",
    "Borno State - Kukawa",
    "Borno State - Kwaya Kusar",
    "Borno State - Mafa",
    "Borno State - Magumeri",
    "Borno State - Maiduguri",
    "Borno State - Marte",
    "Borno State - Mobbar",
    "Borno State - Monguno",
    "Borno State - Ngala",
    "Borno State - Nganzai",
    "Borno State - Shani",

    "Cross River State - Abi",
    "Cross River State - Akamkpa",
    "Cross River State - Akpabuyo",
    "Cross River State - Bakassi",
    "Cross River State - Bekwara",
    "Cross River State - Biase",
    "Cross River State - Boki",
    "Cross River State - Calabar",
    "Cross River State - Etung",
    "Cross River State - Ikom",
    "Cross River State - Obanliku",
    "Cross River State - Obubra",
    "Cross River State - Obudu",
    "Cross River State - Odukpani",
    "Cross River State - Ogoja",
    "Cross River State - Yakuur",
    "Cross River State - Yala",

    "Delta State - Asaba",
    "Delta State - Aniocha North",
    "Delta State - Aniocha South",
    "Delta State - Bomadi",
    "Delta State - Burutu",
    "Delta State - Ethiope East",
    "Delta State - Ethiope West",
    "Delta State - Ika North East",
    "Delta State - Ika South",
    "Delta State - Isoko",
    "Delta State - Ndokwa East",
    "Delta State - Ndokwa West",
    "Delta State - Okpe",
    "Delta State - Oshimili North",
    "Delta State - Oshimili South",
    "Delta State - Patani",
    "Delta State - Sapele",
    "Delta State - Udu",
    "Delta State - Ughelli",
    "Delta State - Uvwie",
    "Delta State - Warri",

    "Ebonyi State - Abakaliki",
    "Ebonyi State - Afikpo North",
    "Ebonyi State - Afikpo South",
    "Ebonyi State - Ebonyi",
    "Ebonyi State - Ezza",
    "Ebonyi State - Ikwo",
    "Ebonyi State - Ishielu",
    "Ebonyi State - Ivo",
    "Ebonyi State - Izzi",
    "Ebonyi State - Ohaozara",
    "Ebonyi State - Ohaukwu",
    "Ebonyi State - Onicha",

    "Edo State - Akoko-Edo",
    "Edo State - Auchi",
    "Edo State - Benin City",
    "Edo State - Egor",
    "Edo State - Ekpoma",
    "Edo State - Esan North East",
    "Edo State - Fugar",
    "Edo State - Igueben",
    "Edo State - Ikpoba-Okha",
    "Edo State - Irrua",
    "Edo State - Uromi",
    "Edo State - Okada",
    "Edo State - Orhionmwon",
    "Edo State - Ovia South",
    "Edo State - Owan",
    "Edo State - Ubiaja",
    "Edo State - Uhunmwonde",

    "Ekiti State - Ado Ekiti",
    "Ekiti State - Aiyekire (Gbonyin)",
    "Ekiti State - Aramoko",
    "Ekiti State - Efon",
    "Ekiti State - Emure",
    "Ekiti State - Ido-Osi",
    "Ekiti State - Ijero",
    "Ekiti State - Ikere",
    "Ekiti State - Ikole",
    "Ekiti State - Ilawe",
    "Ekiti State - Ilejemeje",
    "Ekiti State - Irepodun/Ifelodun",
    "Ekiti State - Ise/Orun",
    "Ekiti State - Moba",
    "Ekiti State - Omuo",
    "Ekiti State - Oye",

    "Rivers State - Abua/Odual",
    "Rivers State - Ahoada",
    "Rivers State - Akuku Toru",
    "Rivers State - Andoni",
    "Rivers State - Asari-Toru",
    "Rivers State - Bonny",
    "Rivers State - Degema",
    "Rivers State - Eleme",
    "Rivers State - Emohua",
    "Rivers State - Etche",
    "Rivers State - Gokana",
    "Rivers State - Ikwerre",
    "Rivers State - Khana",
    "Rivers State - Mbiama",
    "Rivers State - Nonwa NYSC",
    "Rivers State - Obio-Akpor",
    "Rivers State - Ogba/Egbema/Ndoni",
    "Rivers State - Ogu/Bolo",
    "Rivers State - Okrika",
    "Rivers State - Omuma",
    "Rivers State - Oyigbo",
    "Rivers State - Port-Harcourt",
    "Rivers State - Tai",

    "Enugu State - Aninri",
    "Enugu State - Awgu",
    "Enugu State - Enugu",
    "Enugu State - Ezeagu",
    "Enugu State - Igbo Eze South",
    "Enugu State - Igbo-Etiti",
    "Enugu State - Igbo-Eze North",
    "Enugu State - Isi-Uzo",
    "Enugu State - Nkanu East",
    "Enugu State - Nkanu West",
    "Enugu State - Nsukka",
    "Enugu State - Oji-River",
    "Enugu State - Udenu",
    "Enugu State - Udi",
    "Enugu State - Uzo-Uwani",

    "Federal Capital Territory - Abuja",
    "Federal Capital Territory - Abaji",
    "Federal Capital Territory - Apo District,",
    "Federal Capital Territory - Asokoro",
    "Federal Capital Territory - Bwari",
    "Federal Capital Territory - Central Business District",
    "Federal Capital Territory - Dakibiyu",
    "Federal Capital Territory - Dakwo District",
    "Federal Capital Territory - Dei-Dei",
    "Federal Capital Territory - Duboyi",
    "Federal Capital Territory - Durumi",
    "Federal Capital Territory - Dutse-Alhaji",
    "Federal Capital Territory - Gaduwa",
    "Federal Capital Territory - Galadimawa",
    "Federal Capital Territory - Garki 1",
    "Federal Capital Territory - Garki 2",
    "Federal Capital Territory - Gudu",
    "Federal Capital Territory - Guzape District",
    "Federal Capital Territory - Gwagwa",
    "Federal Capital Territory - Gwagwalada",
    "Federal Capital Territory - Gwarinpa",
    "Federal Capital Territory - Idu Industrial",
    "Federal Capital Territory - Jabi",
    "Federal Capital Territory - Jahi",
    "Federal Capital Territory - Jikwoyi",
    "Federal Capital Territory - Jiwa",
    "Federal Capital Territory - Kabusa",
    "Federal Capital Territory - Kado",
    "Federal Capital Territory - Karmo",
    "Federal Capital Territory - Karshi",
    "Federal Capital Territory - Karu",
    "Federal Capital Territory - Katampe",
    "Federal Capital Territory - Kaura",
    "Federal Capital Territory - Kpeyegyi",
    "Federal Capital Territory - Kubwa",
    "Federal Capital Territory - Kuchigoro",
    "Federal Capital Territory - Kuje",
    "Federal Capital Territory - Kurudu",
    "Federal Capital Territory - Kwali",
    "Federal Capital Territory - Lokogoma",
    "Federal Capital Territory - Lugbe District",
    "Federal Capital Territory - Mabushi",
    "Federal Capital Territory - Maitama",
    "Federal Capital Territory - Mararaba",
    "Federal Capital Territory - Masaka",
    "Federal Capital Territory - Mbora",
    "Federal Capital Territory - Mpape",
    "Federal Capital Territory - Nyanya",
    "Federal Capital Territory - Okanje",
    "Federal Capital Territory - Orozo",
    "Federal Capital Territory - Pyakasa",
    "Federal Capital Territory - Sabo Gida",
    "Federal Capital Territory - Utako",
    "Federal Capital Territory - Wumba",
    "Federal Capital Territory - Wuse",
    "Federal Capital Territory - Wuse 2",
    "Federal Capital Territory - Wuye",
    "Federal Capital Territory - Zuba",

    "Gombe State - Akko",
    "Gombe State - Balanga",
    "Gombe State - Billiri",
    "Gombe State - Dukku",
    "Gombe State - Funakaye",
    "Gombe State - Gombe",
    "Gombe State - Kaltungo",
    "Gombe State - Kwami",
    "Gombe State - Nafada",
    "Gombe State - Shomgom",
    "Gombe State - Yamaltu/Deba",

    "Imo State - Aboh-Mbaise",
    "Imo State - Ahiazu-Mbaise",
    "Imo State - Ehime-Mbano",
    "Imo State - Ezinihitte",
    "Imo State - Ezinihitte Mbaise",
    "Imo State - Ideato North",
    "Imo State - Ideato South",
    "Imo State - Ihitte/Uboma",
    "Imo State - Ikeduru",
    "Imo State - Isiala Mbano",
    "Imo State - Isu",
    "Imo State - Mbaitoli",
    "Imo State - Ngor-Okpala",
    "Imo State - Njaba",
    "Imo State - Nkwerre",
    "Imo State - Nwangele",
    "Imo State - Obowo",
    "Imo State - Oguta",
    "Imo State - Ohaji/Egbema",
    "Imo State - Okigwe",
    "Imo State - Onuimo",
    "Imo State - Orlu",
    "Imo State - Orsu",
    "Imo State - Oru",
    "Imo State - Owerri",

    "Jigawa State - Auyo",
    "Jigawa State - Babura",
    "Jigawa State - Biriniwa",
    "Jigawa State - Buji",
    "Jigawa State - Dutse-Jigawa",
    "Jigawa State - Gagarawa",
    "Jigawa State - Garki",
    "Jigawa State - Gumel",
    "Jigawa State - Guri",
    "Jigawa State - Gwaram",
    "Jigawa State - Gwiwa",
    "Jigawa State - Hadejia",
    "Jigawa State - Jahun",
    "Jigawa State - Kafin Hausa",
    "Jigawa State - Kaugama",
    "Jigawa State - Kazaure",
    "Jigawa State - Kiri Kasamma",
    "Jigawa State - Kiyawa",
    "Jigawa State - Maigatari",
    "Jigawa State - Malam Madori",
    "Jigawa State - Miga",
    "Jigawa State - Ringim",
    "Jigawa State - Roni",
    "Jigawa State - Sule-Tankarkar",
    "Jigawa State - Taura",
    "Jigawa State - Yankwashi",

    "Kaduna State - Birnin-Gwari",
    "Kaduna State - Chikun",
    "Kaduna State - Giwa",
    "Kaduna State - Igabi",
    "Kaduna State - Ikara",
    "Kaduna State - Jaba",
    "Kaduna State - Jema a",
    "Kaduna State - Kachia",
    "Kaduna State - Kaduna",
    "Kaduna State - Kagarko",
    "Kaduna State - Kajuru",
    "Kaduna State - Kaura-Kaduna",
    "Kaduna State - Kauru",
    "Kaduna State - Kubau",
    "Kaduna State - Kudan",
    "Kaduna State - Lere",
    "Kaduna State - Makarfi",
    "Kaduna State - Sanga",
    "Kaduna State - Soba",
    "Kaduna State - Zango-Kataf",
    "Kaduna State - Zaria",

    "Kano State - Ajingi",
    "Kano State - Albasu",
    "Kano State - Bagwai",
    "Kano State - Bebeji",
    "Kano State - Bichi",
    "Kano State - Bunkure",
    "Kano State - Dala",
    "Kano State - Dambatta",
    "Kano State - Dawakin Kudu",
    "Kano State - Dawakin Tofa",
    "Kano State - Doguwa",
    "Kano State - Fagge",
    "Kano State - Gabasawa",
    "Kano State - Garko",
    "Kano State - Garum Mallam",
    "Kano State - Garun Mallam",
    "Kano State - Gaya",
    "Kano State - Gezawa",
    "Kano State - Gwale",
    "Kano State - Gwarzo",
    "Kano State - Kabo",
    "Kano State - Kano Municipal",
    "Kano State - Karaye",
    "Kano State - Kibiya",
    "Kano State - Kiru",
    "Kano State - Kumbotso",
    "Kano State - Kunchi",
    "Kano State - Kura",
    "Kano State - Madobi",
    "Kano State - Makoda",
    "Kano State - Minjibir",
    "Kano State - Nasarawa-Kano",
    "Kano State - Rano",
    "Kano State - Rimin Gado",
    "Kano State - Rogo",
    "Kano State - Shanono",
    "Kano State - Sumaila",
    "Kano State - Takai",
    "Kano State - Tarauni",
    "Kano State - Tofa",
    "Kano State - Tsanyawa",
    "Kano State - Tudun Wada",
    "Kano State - Ungogo",
    "Kano State - Warawa",
    "Kano State - Wudil",

    "Katsina State - Bakori",
    "Katsina State - Batagarawa",
    "Katsina State - Batsari",
    "Katsina State - Baure",
    "Katsina State - Bindawa",
    "Katsina State - Charanchi",
    "Katsina State - Dan Musa",
    "Katsina State - Dandume",
    "Katsina State - Danja",
    "Katsina State - Daura",
    "Katsina State - Dutsi",
    "Katsina State - Dutsin-Ma",
    "Katsina State - Faskari",
    "Katsina State - Funtua",
    "Katsina State - Ingawa",
    "Katsina State - Jibia",
    "Katsina State - Kafur",
    "Katsina State - Kaita",
    "Katsina State - Kankara",
    "Katsina State - Kankia",
    "Katsina State - Katsina",
    "Katsina State - Kurfi",
    "Katsina State - Kusada",
    "Katsina State - Mai adua",
    "Katsina State - Malumfashi",
    "Katsina State - Mani",
    "Katsina State - Mashi",
    "Katsina State - Matazu",
    "Katsina State - Musawa",
    "Katsina State - Rimi",
    "Katsina State - Sabuwa",
    "Katsina State - Safana",
    "Katsina State - Sandamu",
    "Katsina State - Zango",

    "Kebbi State - Aleiro",
    "Kebbi State - Arewa-Dandi",
    "Kebbi State - Argungu",
    "Kebbi State - Augie",
    "Kebbi State - Bagudo",
    "Kebbi State - Birnin Kebbi",
    "Kebbi State - Bunza",
    "Kebbi State - Dandi",
    "Kebbi State - Fakai",
    "Kebbi State - Gwandu",
    "Kebbi State - Jega",
    "Kebbi State - Kalgo",
    "Kebbi State - Koko/Besse",
    "Kebbi State - Maiyama",
    "Kebbi State - Ngaski",
    "Kebbi State - Sakaba",
    "Kebbi State - Shanga",
    "Kebbi State - Suru",
    "Kebbi State - Wasagu/Danko",
    "Kebbi State - Yauri",
    "Kebbi State - Zuru",

    "Kogi State - Adavi",
    "Kogi State - Ajaokuta",
    "Kogi State - Ankpa",
    "Kogi State - Bassa",
    "Kogi State - Dekina",
    "Kogi State - Ibaji",
    "Kogi State - Idah",
    "Kogi State - Igala Mela",
    "Kogi State - Igalamela-Odolu",
    "Kogi State - Ijumu",
    "Kogi State - Kabba/Bunu",
    "Kogi State - Kogi",
    "Kogi State - Koton Karfe",
    "Kogi State - Lokoja",
    "Kogi State - Mopa-Muro",
    "Kogi State - Ofu",
    "Kogi State - Ogori/Magongo",
    "Kogi State - Okehi",
    "Kogi State - Okene",
    "Kogi State - Olamaboro",
    "Kogi State - Omala",
    "Kogi State - Yagba East",
    "Kogi State - Yagba West",

    "Kwara State - Asa",
    "Kwara State - Baruten",
    "Kwara State - Edu",
    "Kwara State - Ekiti-Kwara",
    "Kwara State - Ifelodun-Kwara",
    "Kwara State - Ilorin East",
    "Kwara State - Ilorin South",
    "Kwara State - Ilorin West",
    "Kwara State - Irepodun-Kwara",
    "Kwara State - Isin",
    "Kwara State - Kaiama",
    "Kwara State - Moro",
    "Kwara State - Oke-Ero",
    "Kwara State - Oyun",
    "Kwara State - Pategi",

    "Lagos - Abule Egba",
    "Lagos - Agbara-Igbesan",
    "Lagos - Agboyi/Ketu",
    "Lagos - Agege",
    "Lagos - Ajah",
    "Lagos - Ataw",
    "Lagos - Alimosho",
    "Lagos - Amuwo-Odofin",
    "Lagos - Badagry",
     "Lagos- Bariga",
    "Lagos - Egbe Idimu",
    "Lagos - Ejigbo",
    "Lagos - Eko Atlantic",
    "Lagos - Epe",
    "Lagos - Festac",
    "Lagos - Gbagada",
    "Lagos - Ibeju",
    "Lagos - Ifako-Ijaiye",
    "Lagos - Ikeja",
    "Lagos - Ikorodu",
    "Lagos - Ikotun/Igando",
    "Lagos - Ikoyi",
    "Lagos - Ilashe",
    "Lagos - Ijesha",
    "Lagos - Ilupeju",
    "Lagos - Iyana-Ipaja",
    "Lagos - Ipaja",
    "Lagos - Isolo",
    "Lagos - Kosofe",
    "Lagos - Lagos Island (Eko)",
    "Lagos - Lekki",
    "Lagos - Magodo",
    "Lagos - Maryland",
    "Lagos - Mazamaza/Volks",
    "Lagos - Mile 2",
    "Lagos - Mile 12",
    "Lagos - Mushin",
    "Lagos - Ogba",
    "Lagos - Ogudu",
    "Lagos - Ojo",
    "Lagos - Ojodu",
    "Lagos - Ojota",
    "Lagos - Okota",
    "Lagos - Orile",
    "Lagos - Oshodi",
    "Lagos - Shomolu",
    "Lagos - Surulere",
    "Lagos - Tarkwa Bay Island",
    "Lagos - Victoria Island",
    "Lagos - Yaba",

    "Nasarawa State - Akwanga",
    "Nasarawa State - Awe",
    "Nasarawa State - Doma",
    "Nasarawa State - Karu-Nasarawa",
    "Nasarawa State - Keana",
    "Nasarawa State - Keffi",
    "Nasarawa State - Kokona",
    "Nasarawa State - Lafia",
    "Nasarawa State - Nasarawa",
    "Nasarawa State - Nasarawa-Eggon",
    "Nasarawa State - Obi-Nasarawa",
    "Nasarawa State - Toto",

    "Niger State - Agaie",
    "Niger State - Agwara",
    "Niger State - Bida",
    "Niger State - Borgu",
    "Niger State - Bosso",
    "Niger State - Chanchaga",
    "Niger State - Edati",
    "Niger State - Gbako",
    "Niger State - Gurara",
    "Niger State - Kontagora",
    "Niger State - Lapai",
    "Niger State - Lavun",
    "Niger State - Magama",
    "Niger State - Mariga",
    "Niger State - Mashegu",
    "Niger State - Minna",
    "Niger State - Mokwa",
    "Niger State - Muya",
    "Niger State - Paikoro",
    "Niger State - Rafi",
    "Niger State - Rijau",
    "Niger State - Shiroro",
    "Niger State - Suleja",
    "Niger State - Tafa",
    "Niger State - Wushishi",

    "Ogun State - Abeokuta North",
    "Ogun State - Abeokuta South",
    "Ogun State - Ado-Odo/Ota",
    "Ogun State - Ajibandele",
    "Ogun State - Ayetoro",
    "Ogun State - Ewekoro",
    "Ogun State - Ifo",
    "Ogun State - Ijebu",
    "Ogun State - Ijebu Ode",
    "Ogun State - Ikenne",
    "Ogun State - Ilaro",
    "Ogun State - Imeko Afon",
    "Ogun State - Ipokia",
    "Ogun State - Obafemi-Owode",
    "Ogun State - Odeda",
    "Ogun State - Odogbolu",
    "Ogun State - Ogun Waterside",
    "Ogun State - Remo North",
    "Ogun State - Sagamu",

    "Ondo State - Akungba",
    "Ondo State - Akure",
    "Ondo State - Ese-Odo",
    "Ondo State - Idanre",
    "Ondo State - Ifedore",
    "Ondo State - Iju/Itaogbolu",
    "Ondo State - Ikare Akoko",
    "Ondo State - Ilaje",
    "Ondo State - Ile-Oluji-Okeigbo",
    "Ondo State - Irele",
    "Ondo State - Isua",
    "Ondo State - Odigbo",
    "Ondo State - Oka",
    "Ondo State - Okeagbe",
    "Ondo State - Okeigbo",
    "Ondo State - Okitipupa",
    "Ondo State - Ondo",
    "Ondo State - Ose",
    "Ondo State - Owo",

    "Osun State - Aiyedade",
    "Osun State - Aiyedire",
    "Osun State - Atakumosa East",
    "Osun State - Atakumosa West",
    "Osun State - Boluwaduro",
    "Osun State - Ede",
    "Osun State - Egbedore",
    "Osun State - Ife",
    "Osun State - Ifedayo",
    "Osun State - Ifelodun-Osun",
    "Osun State - Ila",
    "Osun State - Ilesa",
    "Osun State - Irepodun-Osun",
    "Osun State - Irewole",
    "Osun State - Isokan",
    "Osun State - Iwo",
    "Osun State - Obokun",
    "Osun State - Ola-Oluwa",
    "Osun State - Olorunda-Osun",
    "Osun State - Oriade",
    "Osun State - Orolu",
    "Osun State - Osogbo",

    "Oyo State - Afijio",
    "Oyo State - Akinyele",
    "Oyo State - Atiba",
    "Oyo State - Atisbo",
    "Oyo State - Ayete",
    "Oyo State - Egbeda",
    "Oyo State - Eruwa",
    "Oyo State - Ibadan",
    "Oyo State - Ido",
    "Oyo State - Igbo Ora",
    "Oyo State - Irepo",
    "Oyo State - Iseyin",
    "Oyo State - Itesiwaju",
    "Oyo State - Iwajowa",
    "Oyo State - Kajola",
    "Oyo State - Lagelu",
    "Oyo State - Ogbomosho North",
    "Oyo State - Ogbomosho South",
    "Oyo State - Ogo Oluwa",
    "Oyo State - Olorunsogo",
    "Oyo State - Oluyole",
    "Oyo State - Ona-Ara",
    "Oyo State - Orelope",
    "Oyo State - Ori Ire",
    "Oyo State - Oyo",
    "Oyo State - Saki East",
    "Oyo State - Saki West",
    "Oyo State - Surulere-Oyo",

    "Plateau State - Barkin Ladi",
    "Plateau State - Bassa-Plateau",
    "Plateau State - Bokkos",
    "Plateau State - Jos",
    "Plateau State - Kanam",
    "Plateau State - Kanke",
    "Plateau State - Langtang North",
    "Plateau State - Langtang South",
    "Plateau State - Mangu",
    "Plateau State - Mikang",
    "Plateau State - Pankshin",
    "Plateau State - Quaan Pan",
    "Plateau State - Riyom",
    "Plateau State - Shendam",
    "Plateau State - Wase",

    "Sokoto State - Binji",
    "Sokoto State - Bodinga",
    "Sokoto State - Dange-Shuni",
    "Sokoto State - Gada",
    "Sokoto State - Goronyo",
    "Sokoto State - Gudu",
    "Sokoto State - Gwadabawa",
    "Sokoto State - Illela",
    "Sokoto State - Isa",
    "Sokoto State - Kebbe",
    "Sokoto State - Kware",
    "Sokoto State - Rabah",
    "Sokoto State - Sabon Birni",
    "Sokoto State - Shagari",
    "Sokoto State - Silame",
    "Sokoto State - Sokoto North",
    "Sokoto State - Sokoto South",
    "Sokoto State - Tambuwal",
    "Sokoto State - Tangaza",
    "Sokoto State - Tureta",
    "Sokoto State - Wamako",
    "Sokoto State - Wurno",
    "Sokoto State - Yabo",

    "Taraba State - Ardo-Kola",
    "Taraba State - Bali",
    "Taraba State - Donga",
    "Taraba State - Gashaka",
    "Taraba State - Gassol",
    "Taraba State - Ibi",
    "Taraba State - Jalingo",
    "Taraba State - Karim-Lamido",
    "Taraba State - Kurmi",
    "Taraba State - Lau",
    "Taraba State - Sardauna",
    "Taraba State - Takum",
    "Taraba State - Ussa",
    "Taraba State - Wukari",
    "Taraba State - Yorro",
    "Taraba State - Zing",

    "Yobe State - Bade",
    "Yobe State - Bursari",
    "Yobe State - Damaturu",
    "Yobe State - Fika",
    "Yobe State - Fune",
    "Yobe State - Geidam",
    "Yobe State - Gujba",
    "Yobe State - Gulani",
    "Yobe State - Jakusko",
    "Yobe State - Karasuwa",
    "Yobe State - Machina",
    "Yobe State - Nangere",
    "Yobe State - Nguru",
    "Yobe State - Potiskum",
    "Yobe State - Tarmua",
    "Yobe State - Yunusari",
    "Yobe State - Yusufari",

    "Zamfara State - Anka",
    "Zamfara State - Bakura",
    "Zamfara State - Birnin Magaji",
    "Zamfara State - Bukkuyum",
    "Zamfara State - Bungudu",
    "Zamfara State - Gummi",
    "Zamfara State - Gusau",
    "Zamfara State - Kaura Namoda",
    "Zamfara State - Maradun",
    "Zamfara State - Maru",
    "Zamfara State - Shinkafi",
    "Zamfara State - Talata Mafara",
    "Zamfara State - Tsafe",
    "Zamfara State - Zurmi"
  ]
};
