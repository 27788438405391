<template>
    <div class=" search-container ">
            <v-btn  :style="{backgroundColor : companyData.companyColor ? companyData.companyColor : 'var(--primaryColor)' }" class="search" text :type="actionType">
                <v-icon color="#ffff">mdi-magnify</v-icon>
            </v-btn>

<!--          <v-btn v-else class="search" text type="submit">Search</v-btn>-->

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
      name: "SearchButton",
      props:{
        actionType:{
          type: [String],
          default: "none"
        }
      },
     computed:{
       ...mapGetters("companyProfile", ["companyData"]),
     }
    }
</script>

<style lang='scss' scoped>
   .search-container{
    @media screen and(max-width: 1024px) {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0.5rem;
    }
   }
    .search {
        border-radius: 10px;
        width: 52px;
        height: 49px !important;
        cursor: pointer;

    }

    .mobile {
        display: none;

        @media (max-width:1024px) {
            display: block;
            font-family: 'Inter';
            font-style: normal;
            text-align: center;
            font-weight: 500;
            cursor: pointer;
            font-size: 14px;
            line-height: 50px;
            color: #FDFFFC;

            width: 100%;
            border-radius: 7px;
            height: 52px;
            margin-top: 30px;
        }
    }
</style>